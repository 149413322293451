<template>
  <q-page class="bg-accent">
    <div class="q-pa-lg d-flex justify-center">
      <q-card bordered flat class="basic-data bg-white">
        <q-card-section class="bg-primary">
          <div class="text-white text-h5">Basic data 2/2</div>
        </q-card-section>
        <q-card-section>
          <span class="egp-lead-label">EGP Lead: {{ UserMail }}</span>
          <q-btn
            color="grey"
            label="Add user to EGP"
            icon="person_add"
            class="q-mt-md q-mb-md q-ml-md q-mr-md"
            height="40px"
            style="
              background-color: #ff7500;
              color: white;
              border-radius: 8px;
              padding: 10px 20px;
            "
          />
          <div class="editable-cell-container">
            <div class="q-mb-sm">Target countries/regions</div>
            <q-select
              v-model="selectedCountries"
              :options="options"
              @update:model-value="handleModelValueUpdate"
              emit-value
              map-options
              multiple
              style="width: max-content; max-width: 90vw; min-width: 200px"
            >
              <span v-if="selectedCountries.length === 0" class="text-grey"
                >Select an option</span
              >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">No results</q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <q-table
            flat
            dense
            bordered
            :rows="plannedDates"
            :columns="plannedDatesColumns"
            :rows-per-page-options="[0]"
            row-key="id"
          >
            <template v-slot:body="props">
              <q-tr v-if="shouldShowRow(props.row)">
                <q-td>{{ props.row.title }}</q-td>
                <q-td>
                  <div class="row q-col-gutter-sm">
                    <q-select
                      v-model="props.row.month"
                      :options="months"
                      dense
                      options-dense
                      class="col"
                    />
                    <q-select
                      v-model="props.row.year"
                      :options="years"
                      dense
                      options-dense
                      class="col"
                    />
                  </div>
                </q-td>
                <q-td>
                  <q-select
                    v-model="props.row.requestODD"
                    :options="requestODDOptions"
                    dense
                    options-dense
                  />
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="Save and continue" @click="onSave" />
        </q-card-actions>
        <ErrorDialog
          v-model="errorDialog"
          :message="errorMessage"
          @close-dialog="closeErrorDialog"
        />
      </q-card>
    </div>

    <!-- Add User Dialog -->
    <q-dialog v-model="showAddUserDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">Add user to EGP</div>
        </q-card-section>
        <q-card-section>
          <q-input v-model="newUserEmail" label="Email" />
          <q-select v-model="newUserRole" :options="roleOptions" label="Role" />
          <q-select
            v-model="selectedExistingUser"
            :options="existingUsers"
            label="Select Existing User"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="Add" @click="addUserToEgp" />
          <q-btn
            color="secondary"
            label="Cancel"
            @click="showAddUserDialog = false"
          />
        </q-card-actions>
        <q-card-section>
          <div class="text-h6">Added Users</div>
          <q-list>
            <q-item v-for="user in addedUsers" :key="user.email">
              <q-item-section
                >{{ user.email }} - {{ user.role }}</q-item-section
              >
            </q-item>
          </q-list>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="Send Invite" @click="sendInvites" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import ErrorDialog from '@/core_controls/GipamErrorDialog/GipamErrorDialogView.vue'
import router from '@/router'
import store from '@/store.js'
import axios from 'axios'

export default {
  components: {
    ErrorDialog,
  },
  name: 'newEgp',
  data() {
    return {
      UserMail: '',
      routeEgpId: '',
      errorDialog: false,
      selectedCountries: [],
      requestODDOptions: ['yes', 'no'],
      options: [
        'Global',
        'Africa',
        'Asia (ex. Japan & China)',
        'Australia',
        'Canada',
        'China',
        'European Union',
        'Japan',
        'South America',
        'United Kingdom',
        'United States',
      ],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      years: Array.from({ length: 21 }, (_, i) => 2020 + i),
      plannedDatesColumns: [
        {
          name: 'title',
          label: 'Planned dates for regulatory approvals',
          field: 'title',
          align: 'center',
        },
        {
          name: 'monthYear',
          label: 'Month/Year',
          field: 'monthYear',
          align: 'center',
        },
        {
          name: 'requestODD',
          label: 'Request for ODD (Orphan Drug Designation)?',
          field: 'requestODD',
          align: 'center',
        },
      ],
      plannedDates: [
        {
          id: 1,
          title: 'EMA (European Medicines Agency) - EU',
          month: null,
          year: null,
          requestODD: null,
        },
        {
          id: 2,
          title: 'FDA (Food and Drug Administration) - US',
          month: null,
          year: null,
          requestODD: null,
        },
        {
          id: 3,
          title: 'Health Canada',
          month: null,
          year: null,
          requestODD: null,
        },
        {
          id: 4,
          title:
            'MHRA (Medicines and Healthcare products Regulatory Agency) - UK',
          month: null,
          year: null,
          requestODD: null,
        },
        {
          id: 5,
          title: 'NMPA (National Medical Products Administration) - China',
          month: null,
          year: null,
          requestODD: null,
        },
        {
          id: 6,
          title: 'PMDA (Pharmaceuticals and Medical Devices Agency) - Japan',
          month: null,
          year: null,
          requestODD: null,
        },
        {
          id: 7,
          title: 'TGA (Therapeutic Goods Administration) - Australia',
          month: null,
          year: null,
          requestODD: null,
        },
      ],
      showAddUserDialog: false,
      newUserEmail: '',
      newUserRole: '',
      roleOptions: ['Admin', 'Editor', 'Viewer'],
      addedUsers: [],
      existingUsers: [],
      selectedExistingUser: null,
    }
  },
  mounted() {
    this.fetchBasicData()
    this.routeEgpId = this.$route.query.egpId
    this.fetchUserEmail()
    this.fetchExistingUsers()
  },
  computed: {
    shouldShowRow() {
      return (row) => {
        const selectedCountriesSet = new Set(
          this.selectedCountries.map((country) => country.toLowerCase()),
        )
        const rowTitle = row.title.toLowerCase()
        if (selectedCountriesSet.has('global')) return true
        if (
          rowTitle.includes('european') &&
          selectedCountriesSet.has('european union')
        )
          return true
        if (
          rowTitle.includes('us') &&
          selectedCountriesSet.has('united states') &&
          !rowTitle.includes('australia')
        )
          return true
        if (rowTitle.includes('canada') && selectedCountriesSet.has('canada'))
          return true
        if (
          rowTitle.includes('uk') &&
          selectedCountriesSet.has('united kingdom')
        )
          return true
        if (rowTitle.includes('china') && selectedCountriesSet.has('china'))
          return true
        if (rowTitle.includes('japan') && selectedCountriesSet.has('japan'))
          return true
        if (
          rowTitle.includes('australia') &&
          selectedCountriesSet.has('australia')
        )
          return true

        return false
      }
    },
  },
  methods: {
    closeErrorDialog() {
      this.errorDialog = false
    },
    async onSave() {
      try {
        // Filter the plannedDates array to include only the visible rows
        const visibleRows = this.plannedDates.filter(this.shouldShowRow)

        const basicData = visibleRows.map((item) => ({
          selectedCountries: this.selectedCountries,
          egpId: store.getters.getCurrentEgpId,
          title: item.title,
          month: item.month,
          year: item.year,
          requestODD: item.requestODD,
        }))
        if (basicData.length === 0) {
          this.errorDialog = true
          this.errorMessage = 'Please select at least one country/region'
          return
        }
        await axios.post('/egp/saveBasicData', basicData)

        store.commit('changeFrageId', 1)
        router.push('/Questionnaire/')
      } catch (err) {
        console.log(err.response)
      }
    },
    handleModelValueUpdate(value) {
      this.selectedCountries = value
    },
    async fetchUserEmail() {
      try {
        var egpId = store.getters.getCurrentEgpId
        if (!egpId) {
          egpId = this.$route.query.egpId
        }
        const response = await axios.get('/egp/getUserMail/' + egpId)
        this.UserMail = response.data
      } catch (error) {
        console.error('Error fetching user email:', error)
      }
    },
    async fetchBasicData() {
      try {
        var egpId = this.$route.query.egpId
        if (!egpId) {
          egpId = store.getters.getCurrentEgpId
        }
        const response = await axios.get('/egp/getBasicData/' + egpId)

        if (response.data && response.data.length > 0) {
          this.selectedCountries = response.data[0].selectedCountries

          // Create a new array to store the merged data
          const mergedPlannedDates = [...this.plannedDates]

          // Loop through the response data and update the corresponding items in the mergedPlannedDates array
          response.data.forEach((item) => {
            const existingItem = mergedPlannedDates.find(
              (plannedDate) => plannedDate.title === item.title,
            )
            if (existingItem) {
              existingItem.month = item.month
              existingItem.year = item.year
              existingItem.requestODD = item.requestODD
            }
          })

          // Update the plannedDates array with the merged data
          this.plannedDates = mergedPlannedDates
        }
      } catch (error) {
        console.error('Error fetching basic data:', error)
      }
    },
    async addUserToEgp() {
      if (this.newUserEmail && this.newUserRole) {
        this.addedUsers.push({
          email: this.newUserEmail,
          role: this.newUserRole,
        })
        this.newUserEmail = ''
        this.newUserRole = ''
      } else if (this.selectedExistingUser) {
        this.addedUsers.push(this.selectedExistingUser)
        this.selectedExistingUser = null
      }
    },
    async sendInvites() {
      try {
        await axios.post('/egp/sendInvites', {
          egpId: store.getters.getCurrentEgpId,
          users: this.addedUsers,
        })
        this.showAddUserDialog = false
        this.addedUsers = []
      } catch (error) {
        console.error('Error sending invites:', error)
      }
    },
    async fetchExistingUsers() {
      try {
        const response = await axios.get('/egp/getExistingUsers')
        this.existingUsers = response.data.map((user) => ({
          label: `${user.email} - ${user.role}`,
          value: user,
        }))
      } catch (error) {
        console.error('Error fetching existing users:', error)
      }
    },
  },
}
</script>

<style scoped>
/* Zusätzliche Stile für den Button */
.q-btn {
  transition:
    background-color 0.3s,
    transform 0.3s;
}

.q-btn:hover {
  background-color: #e66a00;
  transform: scale(1.05);
}
</style>
