import router from '@/router.js'
import store from '@/store.js'
import axios from 'axios'
import Quasar from 'quasar/src/vue-plugin.js';
import { createApp } from 'vue'
import App from './App.vue'
import quasarUserOptions from './quasar-user-options'

const app = createApp(App)
app.use(router)
app.use(Quasar, quasarUserOptions)
app.use(store)

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.request.use((config) => {
  if (store.state.user.token) {
    config.headers.Authorization = `Bearer ${store.state.user.token}`
  }
  config.headers.common.Accept = 'Application/json'
  config.headers['Access-Control-Allow-Origin'] = '*'
  return config
})

app.mount('#app')
