<template>
  <q-page class="bg-accent">
    <div class="q-pa-md">
      <q-btn rounded color="primary" @click="onSupport">
        <img src="@/assets/Short_logo.png" alt="Logo" class="logo" />
        <div class="text-white">Get help in developing this EGP</div>
      </q-btn>
    </div>
    <div class="q-pa-lg d-flex justify-center">
      <q-card align="center" bordered flat class="basic-data bg-white">
        <q-card-section class="bg-primary">
          <div class="text-white text-h5">Basic data 1/2</div>
        </q-card-section>
        <q-card-section>
          <q-input
            ref="inputRef"
            class="q-pa-sm"
            outlined
            dense
            v-model="nameEgp"
            label="Name EGP"
            :rules="[(val) => !!val || 'Field is required']"
          />
          <q-input
            class="q-pa-sm"
            outlined
            dense
            v-model="asset"
            label="Asset"
            :rules="[(val) => !!val || 'Field is required']"
          />
        </q-card-section>
        <q-card-section>
          <div class="text-subtitle2">
            <p class="left-align-header">
              Welcome to the EVIGATOR, the tool that will support you in
              developing and executing an Evidence Generation Plan (EGP). The
              software has currently the following modules:
            </p>
            <ul class="left-align">
              <li>
                Development of an EGP: About 20-30 questions will guide you
                through the development, the results will be a structured EGP
                for implementation or further discussions. You will need about
                15-20 minutes to create a first draft.
              </li>
              <li>Pressure test and revision of an existing EGP</li>
              <li>
                Implementation of an EGP: Monitoring and reporting system for
                ongoing/completed research projects
              </li>
            </ul>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="Save and continue" @click="onSave" />
        </q-card-actions>
      </q-card>
    </div>
    <q-dialog v-model="showNameRequiredDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">Please enter a name for your EGP</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="OK" @click="closeNameRequiredDialog" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Support Dialog -->
    <q-dialog v-model="showSupportDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">Let us support you!</div>
          <p>
            Please briefly describe your request and we will get back to you
            immediately!
          </p>
        </q-card-section>

        <q-card-section>
          <q-input v-model="supportForm.sender" label="Sender" />
          <q-input
            v-model="supportForm.message"
            label="Message"
            type="textarea"
          />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn
            flat
            label="Send Request"
            color="primary"
            @click="sendSupportRequest"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Feedback Dialog -->
    <q-dialog v-model="showFeedbackDialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">{{ feedbackMessage }}</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import router from '@/router'
import store from '@/store.js'
import axios from 'axios'
import { computed, onMounted, ref } from 'vue'

export default {
  name: 'newEgp',
  setup() {
    const inputRef = ref(null)
    const nameEgp = ref('')
    const asset = ref('')
    const tpp = ref(null)
    const selectedFileName = ref('')
    const showNameRequiredDialog = ref(false)
    const showSupportDialog = ref(false)
    const showFeedbackDialog = ref(false)
    const feedbackMessage = ref('')
    const userName = ref('')
    const supportForm = ref({
      sender: '',
      header: 'Help with new EGP',
      message: '',
    })

    const egpId = computed(() => store.getters.getCurrentEgpId)

    const isNameEgpValid = computed(() => nameEgp.value.trim() !== '')
    const isAssetValid = computed(() => asset.value.trim() !== '')

    onMounted(async () => {
      try {
        if (egpId.value) {
          const response = await axios.get('/egp/getUserMail/' + egpId.value)
          userName.value = response.data
          supportForm.value.sender = userName.value
        }
      } catch (error) {
        console.error('Failed to fetch user email:', error)
      }
    })

    const onSave = async () => {
      if (!isNameEgpValid.value || !isAssetValid.value) {
        showNameRequiredDialog.value = true
        return
      }
      try {
        let newEpg = { id: -1, name: nameEgp.value, asset: asset.value }
        let responseSave = await axios.post('/egp/save', newEpg)

        store.commit('changeEgpId', responseSave.data)
        store.commit('changeFrageId', -1)
        router.push('/BasicData/')
      } catch (err) {
        console.log(err.response)
      }
    }

    const closeNameRequiredDialog = () => {
      showNameRequiredDialog.value = false
    }

    const onSupport = () => {
      showSupportDialog.value = true
    }

    const sendSupportRequest = async () => {
      try {
        const fullMessage = `
          EgpId: ${egpId.value || 'N/A'}
          User Name: ${userName.value || 'N/A'}
          Message:
          ${supportForm.value.message}
        `

        await axios.post('common/sendMail', {
          ...supportForm.value,
          message: fullMessage,
        })
        feedbackMessage.value =
          'Your support request has been sent. We will get back to you shortly.'
      } catch (error) {
        console.error('Error sending support request:', error)
        feedbackMessage.value =
          'There was a problem sending your request. Please try again later'
      } finally {
        showSupportDialog.value = false
        showFeedbackDialog.value = true
      }
    }

    const openFileInput = () => {
      // Implementation needed
    }

    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0]
      if (selectedFile) {
        tpp.value = selectedFile
        selectedFileName.value = selectedFile.name
      } else {
        tpp.value = null
        selectedFileName.value = ''
      }
    }

    return {
      inputRef,
      nameEgp,
      asset,
      tpp,
      selectedFileName,
      showNameRequiredDialog,
      showSupportDialog,
      showFeedbackDialog,
      feedbackMessage,
      supportForm,
      isNameEgpValid,
      isAssetValid,
      onSave,
      closeNameRequiredDialog,
      onSupport,
      sendSupportRequest,
      openFileInput,
      handleFileChange,
    }
  },
}
</script>
