<template>
  <div class="q-pa-md" style="text-align: right">
    <q-btn
      rounded
      bordered
      style="border-color: #ff7500"
      @click="showSupportDialog = true"
    >
      <img src="@/assets/Short_logo.png" alt="Logo" style="height: 22px" />
      <div class="text-primary">Get help in developing this EGP</div>
    </q-btn>
  </div>

  <q-dialog v-model="showSupportDialog">
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6">Let us support you!</div>
        <p>
          Please briefly describe your request and we will get back to you
          immediately!
        </p>
      </q-card-section>

      <q-card-section>
        <q-input v-model="userName" label="Username" readonly dense filled />
        <q-input
          v-model="supportForm.message"
          label="Message"
          type="textarea"
          class="q-mt-md"
        />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="primary" v-close-popup />
        <q-btn
          flat
          label="Send Request"
          color="primary"
          @click="sendSupportRequest"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showFeedbackDialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ feedbackMessage }}</div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import QuestionService from '@/service/QuestionService.ts'
import axios from 'axios'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const showSupportDialog = ref(false)
const showFeedbackDialog = ref(false)
const feedbackMessage = ref('')
const userName = ref('')
const currentQuestionLfdNr = ref('')

// Use computed properties to get values from the store
const egpId = computed(() => store.getters.getCurrentEgpId)

const supportForm = ref({
  header: 'Help with Questions',
})

onMounted(async () => {
  try {
    const response = await axios.get('/egp/getUserMail/' + egpId.value)
    userName.value = response.data

    const currentQuestion = await QuestionService.getCurrentQuestion(
      store.getters.getCurrentFrageId,
      store.getters.getCurrentEgpId,
    )
    currentQuestionLfdNr.value = currentQuestion.lfdNr
  } catch (error) {
    console.error('Failed to fetch user email or current question:', error)
  }
})

const sendSupportRequest = async () => {
  try {
    const fullMessage = `
      EgpId: ${egpId.value}
      Question LfdNr: ${currentQuestionLfdNr.value}
      User Name: ${userName.value}
      Message:
      ${supportForm.value.message}
    `

    await axios.post('common/sendMail', {
      ...supportForm.value,
      message: fullMessage,
    })
    feedbackMessage.value =
      'Your support request has been sent. We will get back to you shortly.'
  } catch (error) {
    feedbackMessage.value =
      'There was a problem sending your request. Please try again later'
  } finally {
    showSupportDialog.value = false
    showFeedbackDialog.value = true
  }
}
</script>

<style scoped>
.text-primary {
  color: #ff7500;
}
</style>
